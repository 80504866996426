import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import withRouter from '../router/withRouter';
import { connect } from 'react-redux';
import { saveVocabShow } from '../react-redux/actions/studyAction';

class StudySuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listVocab: this.props.vocabStudy,
            shuffledListVocab: [],
            selectedListVocab: [],
            matchedListVocab: [],
        }
    }

    componentDidMount() {
        this.shuffleItems();
    }

    shuffleItems = () => {
        const names = this.state.listVocab.map((item) => ({ ...item, type: 'name', display: item.name }));
        const means = this.state.listVocab.map((item) => ({ ...item, type: 'mean', display: item.mean }));
        const combined = [...names, ...means].sort(() => Math.random() - 0.5);
        this.setState({ shuffledListVocab: combined });
    };

    handleItemClick = (item) => {
        const { selectedListVocab, matchedListVocab } = this.state;

        if (matchedListVocab.includes(item.vocab_id)) return;

        const newSelectedListVocab = [...selectedListVocab, item];

        this.setState({ selectedListVocab: newSelectedListVocab }, () => {
            if (newSelectedListVocab.length === 2) {
                this.checkMatch();
            }
        });
    };

    checkMatch = () => {
        const [first, second] = this.state.selectedListVocab;

        if (
            first.vocab_id === second.vocab_id &&
            ((first.type === 'name' && second.type === 'mean') || (first.type === 'mean' && second.type === 'name'))
        ) {
            this.setState((prevState) => ({
                matchedListVocab: [...prevState.matchedListVocab, first.vocab_id],
                selectedListVocab: [],
            }));
        } else {
            setTimeout(() => {
                this.setState({ selectedListVocab: [] });
            }, 1000);
        }
    };

    clickSaveVocab = () => {
        this.props.saveVocabShow(this.props.vocabStudy, this.props.user_id);
        return <Navigate to="/homeMember" />
    }
    render() {
        const { shuffledListVocab, selectedListVocab, matchedListVocab, listVocab } = this.state;
        const isComplete = matchedListVocab.length === listVocab.length;
        return (
            <div className='studySuccess'>
                <h1 className="h2">Một bài kiểm tra nho nhỏ - Nối từ</h1>
                <div className="container">
                    <ul className="listVocabButton">
                        {shuffledListVocab.map((value) =>
                            <button key={`${value.type}-${value.vocab_id}`} className={`item-button ${matchedListVocab.includes(value.vocab_id)
                                    ? 'matched'
                                    : selectedListVocab.some(
                                        (selected) => selected.vocab_id === value.vocab_id && selected.type === value.type
                                    )
                                        ? 'selected'
                                        : ''
                                }`}
                                onClick={() => this.handleItemClick(value)}
                                disabled={matchedListVocab.includes(value.vocab_id)}>
                                {value.display}
                            </button>
                        )}
                    </ul>
                </div>
                {
                    isComplete ? (
                        <Link to={this.props.linkto}
                            className="btn btn-primary btnVocabCStudy"
                            onClick={this.clickSaveVocab()}
                        >
                            Hoàn thành bài học
                        </Link>
                    ) : (
                        <button className="shuffle-button" onClick={this.shuffleItems}>
                            Đổi vị trí
                        </button>
                    )
                }
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
const mapDispatchToProps = {
    saveVocabShow
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudySuccess));