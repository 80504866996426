import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { updateReview } from '../react-redux/actions/reviewAction';

class ReviewSuccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listVocab: this.props.vocabReview,
            shuffledListVocab: [],
            selectedListVocab: [],
            matchedListVocab: [],
        }
    }

    componentDidMount() {
        this.shuffleItems();
    }

    shuffleItems = () => {
        const names = this.state.listVocab.map((item) => ({ ...item, type: 'name', display: item.name }));
        const means = this.state.listVocab.map((item) => ({ ...item, type: 'mean', display: item.mean }));
        const combined = [...names, ...means].sort(() => Math.random() - 0.5);
        this.setState({ shuffledListVocab: combined });
    };

    handleItemClick = (item) => {
        const { selectedListVocab, matchedListVocab } = this.state;

        if (matchedListVocab.includes(item.vocab_id)) return;

        const newSelectedListVocab = [...selectedListVocab, item];

        this.setState({ selectedListVocab: newSelectedListVocab }, () => {
            if (newSelectedListVocab.length === 2) {
                this.checkMatch();
            }
        });
    };

    checkMatch = () => {
        const [first, second] = this.state.selectedListVocab;

        if (
            first.vocab_id === second.vocab_id &&
            ((first.type === 'name' && second.type === 'mean') || (first.type === 'mean' && second.type === 'name'))
        ) {
            this.setState((prevState) => ({
                matchedListVocab: [...prevState.matchedListVocab, first.vocab_id],
                selectedListVocab: [],
            }));
        } else {
            setTimeout(() => {
                this.setState({ selectedListVocab: [] });
            }, 1000);
        }
    };

    clickUpdateVocab = () => {
        this.props.updateReview(this.props.completedReview);
        return <Navigate to="/homeMember" />
    }
    render() {
        const { shuffledListVocab, selectedListVocab, matchedListVocab, listVocab } = this.state;
        const isComplete = matchedListVocab.length === listVocab.length;
        return (
            <div className='reviewSuccess'>
                <h1 className="h2">Một bài kiểm tra nho nhỏ - Nối từ</h1>
                <ul className="listVocabButton">
                    {shuffledListVocab.map((value) =>
                        <button key={`${value.type}-${value.vocab_id}`} className={`item-button ${matchedListVocab.includes(value.vocab_id)
                            ? 'matched'
                            : selectedListVocab.some(
                                (selected) => selected.vocab_id === value.vocab_id && selected.type === value.type
                            )
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => this.handleItemClick(value)}
                            disabled={matchedListVocab.includes(value.vocab_id)}>
                            {value.display}
                        </button>
                    )}
                </ul>
                {
                    isComplete ? (
                        <div className="listVocabAll">
                            <ul className="listVocab">
                                <p className='completeAndCorrect'>Từ vựng ôn tập thành công</p>
                                {this.props.completedReview.map(value =>
                                    <li key={value.vocab_id}>
                                        <p className="name_vocab">{value.name}</p>
                                        <p className="mean_vocab">{value.mean}</p>
                                    </li>
                                )}
                            </ul>
                            <ul className="listVocab">
                                <p className='completeAndCorrect'>Từ vựng chưa hoàn thành.<br></br> Vui lòng ôn tập lại.</p>
                                {this.props.incorrectThree.map(value =>
                                    <li key={value.vocab_id}>
                                        <p className="name_vocab">{value.name}</p>
                                        <p className="mean_vocab">{value.mean}</p>
                                    </li>
                                )}
                            </ul>
                            <Link to={this.props.linkto}
                                className="btn btn-primary btnVocabCStudy"
                                onClick={this.clickUpdateVocab()}
                            >
                                Tiếp tục
                            </Link>
                        </div>

                    ) : (
                        <button className="shuffle-button" onClick={this.shuffleItems}>
                            Đổi vị trí
                        </button>
                    )
                }
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        review: state.review
    }
}
const mapDispatchToProps = {
    updateReview
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewSuccess);