import React, { Component } from 'react';
import AlertSuccessStudy from './AlertSuccessStudy';
import AlertErrorStudy from './AlertErrorStudy';

class ViewAndWrite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: '',
            showAlertStudy: '',
            isDisabled: false,
        }
    }

    isChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
        if ((value === this.props.name || value === this.props.pronunciation) && value !== '') {
            this.setState({
                showAlertStudy: 'success',
                isDisabled: true
            });
        }
    }

    clickCheck = (event) => {
        event.preventDefault();
        if (this.state.answer === this.props.name || this.state.answer === this.props.pronunciation) {
            this.setState({
                showAlertStudy: 'success',
                isDisabled: true
            });
        } else {
            this.setState({
                showAlertStudy: 'error',
                isDisabled: true
            });
        }
    }
    showButton = () => {
        if (this.state.answer !== '') {
            return (
                <button name="" id="" className="btn btn-primary check" onClick={(event) => this.clickCheck(event)} disabled={this.state.isDisabled}>
                    kiểm tra
                </button>
            )
        }else{
            return (
                <button name="" id="" className="btn btn-primary check" disabled>
                    kiểm tra
                </button>
            )
        }
    }

    render() {
        return (
            <div className="viewAndWrite">
                <h2>Xem và viết đáp án</h2>
                <div className="viewVocab">{this.props.mean + ' / '+ this.props.sino_vietnamese_sound}</div>
                <div className="writeVocab">
                    <input
                        type="text"
                        className="form-control"
                        name="answer"
                        id=""
                        placeholder="Nhập cách đọc hoặc kanji"
                        autoComplete="off"
                        onChange={(event) => this.isChange(event)}
                        disabled={this.state.isDisabled}
                    />
                </div>
                {this.showButton()}
                {this.state.showAlertStudy === 'success' && <AlertSuccessStudy name = {this.props.name} pronunciation = {this.props.pronunciation} mean = {this.props.mean} example = {this.props.example} example_mean = {this.props.example_mean} sound = {this.props.sound} howtolearnNext = {this.props.howToLearnNext}/>}
                {this.state.showAlertStudy === 'error' && <AlertErrorStudy name = {this.props.name} pronunciation = {this.props.pronunciation} mean = {this.props.mean} example = {this.props.example} example_mean = {this.props.example_mean} sound = {this.props.sound} howToLearnBack = {this.props.howToLearnBackWrite} nameButton = {'Học lại'}/>}
            </div>
        );
    }
}

export default ViewAndWrite;