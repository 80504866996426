import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { updateReview } from '../react-redux/actions/reviewAction';

class ReviewSuccessG extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listGrammar: this.props.grammarReview,
            shuffledListGrammar: [],
            selectedListGrammar: [],
            matchedListGrammar: [],
        }
    }

    componentDidMount() {
        this.shuffleItems();
    }

    shuffleItems = () => {
        const example = this.state.listGrammar.map((item) => ({ ...item, type: 'example', display: item.example }));
        const mean_example = this.state.listGrammar.map((item) => ({ ...item, type: 'mean_example', display: item.mean_example }));
        const combined = [...example, ...mean_example].sort(() => Math.random() - 0.5);
        this.setState({ shuffledListGrammar: combined });
    };

    handleItemClick = (item) => {
        const { selectedListGrammar, matchedListGrammar } = this.state;

        if (matchedListGrammar.includes(item.vocab_id)) return;

        const newSelectedListGrammar = [...selectedListGrammar, item];

        this.setState({ selectedListGrammar: newSelectedListGrammar }, () => {
            if (newSelectedListGrammar.length === 2) {
                this.checkMatch();
            }
        });
    };

    checkMatch = () => {
        const [first, second] = this.state.selectedListGrammar;

        if (
            first.grammar_id === second.grammar_id &&
            ((first.type === 'example' && second.type === 'mean_example') || (first.type === 'mean_example' && second.type === 'example'))
        ) {
            this.setState((prevState) => ({
                matchedListGrammar: [...prevState.matchedListGrammar, first.grammar_id],
                selectedListGrammar: [],
            }));
        } else {
            setTimeout(() => {
                this.setState({ selectedListGrammar: [] });
            }, 1000);
        }
    };

    clickUpdateGrammar = () => {
        this.props.updateReview(this.props.completedReview);
        return <Navigate to="/homeMember" />
    }
    render() {
        const { matchedListGrammar, shuffledListGrammar, selectedListGrammar, listGrammar } = this.state;
        const isComplete = matchedListGrammar.length === listGrammar.length;
        return (
            <div className='studySuccessG'>
                <h1 className="h2">Một bài kiểm tra nho nhỏ - Nối ngữ pháp</h1>
                <ul className="listVocabButton listGrammarButton">
                    {shuffledListGrammar.map((value) =>
                        <button key={`${value.type}-${value.grammar_id}`} className={`item-button ${matchedListGrammar.includes(value.grammar_id)
                            ? 'matched'
                            : selectedListGrammar.some(
                                (selected) => selected.grammar_id === value.grammar_id && selected.type === value.type
                            )
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => this.handleItemClick(value)}
                            disabled={matchedListGrammar.includes(value.grammar_id)}>
                            {value.display}
                        </button>
                    )}
                </ul>
                {
                    isComplete ? (
                        <div className="listGrammarAll">
                            <ul className="list">
                                <p className='completeAndCorrect'>Ngữ pháp ôn tập thành công</p>
                                {this.props.completedReview.map(value =>
                                    <li key={value.grammar_id}>
                                        <p className="name">{value.name + ' : '}</p>
                                        <p className="mean">{value.mean}</p>
                                    </li>
                                )}
                            </ul>
                            <ul className="list">
                                <p className='completeAndCorrect'>Từ vựng chưa hoàn thành.<br></br> Vui lòng ôn tập lại.</p>
                                {this.props.incorrectThree.map(value =>
                                    <li key={value.grammar_id}>
                                        <p className="name">{value.name}</p>
                                        <p className="mean">{value.mean}</p>
                                    </li>
                                )}
                            </ul>
                            <Link to={this.props.linkto}
                                className="btn btn-primary btnGrammarStudy"
                                onClick={this.clickUpdateGrammar()}
                            >
                                Tiếp tục
                            </Link>
                        </div>
                    ) : (
                        <button className="shuffle-button" onClick={this.shuffleItems}>
                            Đổi vị trí
                        </button>
                    )
                }
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        review: state.review
    }
}
const mapDispatchToProps = {
    updateReview
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewSuccessG)