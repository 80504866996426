import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { saveGrammarShow } from '../react-redux/actions/studyAction';

class StudySuccessG extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listGrammar: this.props.grammarStudy,
            shuffledListGrammar: [],
            selectedListGrammar: [],
            matchedListGrammar: [],
        }
    }

    componentDidMount() {
        this.shuffleItems();
    }

    shuffleItems = () => {
        const example = this.state.listGrammar.map((item) => ({ ...item, type: 'example', display: item.example }));
        const mean_example = this.state.listGrammar.map((item) => ({ ...item, type: 'mean_example', display: item.mean_example }));
        const combined = [...example, ...mean_example].sort(() => Math.random() - 0.5);
        this.setState({ shuffledListGrammar: combined });
    };

    handleItemClick = (item) => {
        const { selectedListGrammar, matchedListGrammar } = this.state;

        if (matchedListGrammar.includes(item.vocab_id)) return;

        const newSelectedListGrammar = [...selectedListGrammar, item];

        this.setState({ selectedListGrammar: newSelectedListGrammar }, () => {
            if (newSelectedListGrammar.length === 2) {
                this.checkMatch();
            }
        });
    };

    checkMatch = () => {
        const [first, second] = this.state.selectedListGrammar;

        if (
            first.grammar_id === second.grammar_id &&
            ((first.type === 'example' && second.type === 'mean_example') || (first.type === 'mean_example' && second.type === 'example'))
        ) {
            this.setState((prevState) => ({
                matchedListGrammar: [...prevState.matchedListGrammar, first.grammar_id],
                selectedListGrammar: [],
            }));
        } else {
            setTimeout(() => {
                this.setState({ selectedListGrammar: [] });
            }, 1000);
        }
    };
    saveGrammar = () => {
        this.props.saveGrammarShow(this.props.grammarStudy, this.props.user_id);
        return <Navigate to="/homeMember" />
    }
    render() {
        const { matchedListGrammar, shuffledListGrammar, selectedListGrammar, listGrammar } = this.state;
        const isComplete = matchedListGrammar.length === listGrammar.length;
        return (
            <div className='studySuccessG'>
                <h1 className="h2">Một bài kiểm tra nho nhỏ - Nối từ</h1>
                <div className="container">
                    <ul className="listVocabButton listGrammarButton">
                        {shuffledListGrammar.map((value) =>
                            <button key={`${value.type}-${value.grammar_id}`} className={`item-button ${matchedListGrammar.includes(value.grammar_id)
                                ? 'matched'
                                : selectedListGrammar.some(
                                    (selected) => selected.grammar_id === value.grammar_id && selected.type === value.type
                                )
                                    ? 'selected'
                                    : ''
                                }`}
                                onClick={() => this.handleItemClick(value)}
                                disabled={matchedListGrammar.includes(value.grammar_id)}>
                                {value.display}
                            </button>
                        )}
                    </ul>
                </div>
                {
                    isComplete ? (
                        <Link to={this.props.linkto}
                            className="btn btn-primary btnGrammarStudy"
                            onClick={this.saveGrammar()}
                        >
                            Hoàn thành bài học
                        </Link>
                    ) : (
                        <button className="shuffle-button" onClick={this.shuffleItems}>
                            Đổi vị trí
                        </button>
                    )
                }
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
const mapDispatchToProps = {
    saveGrammarShow
}
export default connect(mapStateToProps, mapDispatchToProps)(StudySuccessG);