import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { Navigate } from 'react-router-dom';
import ProgressVocab from '../vocabulary/ProgressVocab';
import { reviewVocab } from '../react-redux/actions/reviewAction';
import ReviewSuccess from '../review/ReviewSuccess';
import ViewAndWrite from '../review/ViewAndWrite';
import ViewAndChooseMean from '../review/ViewAndChooseMean';

class ReviewVocab extends Component {

    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.review.reviewVocabData === null) {
            this.props.reviewVocab()
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }
    constructor(props) {
        super(props);
        this.state = {
            numberHowtolearnNow: 0,
            numberVocabReviewNow: 0,
            score: 0,
            incorrectVocabReview: [],
            completedVocabReview: [],
            incorrectCount: {},
            incorrectThree: []
        }
    }

    howToLearnNext = (vocabReview) => {
        if (this.props.review.reviewVocabData !== null) {
            const { user } = this.props.auth;
            const vocabReviewId = this.props.review.reviewVocabData.filter((value) => value.user_id === user.user_id);
            const vocabReviews = vocabReviewId.slice(0, 12);

            this.setState((prevState) => {
                const numberHowtolearnNext = prevState.numberHowtolearnNow + 1;
                const isLastHowtolearn = numberHowtolearnNext > 1;
                let numberVocabReviewNext = prevState.numberVocabReviewNow;
                if (isLastHowtolearn) {
                    let potentialNext = prevState.numberVocabReviewNow + 1;
                    const vocabReviewsLength = vocabReviews.length;
                    while (prevState.completedVocabReview.includes(potentialNext % vocabReviewsLength)) {
                        potentialNext += 1;
                    }
                    numberVocabReviewNext = potentialNext % vocabReviewsLength;
                }
                const numberHowtolearnReset = isLastHowtolearn ? 0 : numberHowtolearnNext;

                // Tách biệt logic đánh dấu từ đã hoàn thành và từ chưa hoàn thành
                const completedVocabReview = [...prevState.completedVocabReview];
                const incorrectVocabReview = [...prevState.incorrectVocabReview];
                const incorrectCount = { ...prevState.incorrectCount };

                if (isLastHowtolearn && !completedVocabReview.includes(prevState.numberVocabReviewNow)) {
                    completedVocabReview.push(prevState.numberVocabReviewNow);
                    const incorrectIndex = incorrectVocabReview.indexOf(prevState.numberVocabReviewNow);
                    if (incorrectIndex > -1) {
                        incorrectVocabReview.splice(incorrectIndex, 1);
                    }
                }

                // Kiểm tra nếu đã đến cuối danh sách từ vựng
                if (numberVocabReviewNext >= vocabReviews.length) {
                    // Nếu còn từ vựng sai thì quay lại từ sai đầu tiên
                    if (prevState.incorrectVocabReview.length > 0) {
                        return {
                            numberHowtolearnNow: 0,
                            numberVocabReviewNow: prevState.incorrectVocabReview[0], // Quay lại từ sai đầu tiên
                            score: prevState.score + (100 / (vocabReview * 2)),
                            completedVocabReview,
                            incorrectVocabReview,
                            incorrectCount,
                        };
                    } else {
                        return {
                            numberHowtolearnNow: -1,
                            completedVocabReview,
                            score: prevState.score + (100 / (vocabReview * 2)),
                        }; // Kết thúc nếu không còn từ vựng để ôn lại
                    }
                } else {
                    return {
                        numberHowtolearnNow: numberHowtolearnReset,
                        numberVocabReviewNow: numberVocabReviewNext,
                        score: prevState.score + (100 / (vocabReview * 2)),
                        completedVocabReview,
                        incorrectVocabReview,
                        incorrectCount,
                    };
                }
            });
        }
    }

    howToLearnBackWrite = (vocabReview) => {
        if (this.props.review.reviewVocabData !== null) {
            const { user } = this.props.auth;
            const vocabReviewId = this.props.review.reviewVocabData.filter((value) => value.user_id === user.user_id);
            const vocabReviews = vocabReviewId.slice(0, 12);

            this.setState((prevState) => {
                const { numberVocabReviewNow, incorrectVocabReview, completedVocabReview, incorrectThree } = prevState;

                // Sao chép incorrectCount để cập nhật và kiểm tra số lần sai
                const incorrectCount = { ...prevState.incorrectCount };

                // Tăng số lần sai cho từ hiện tại
                incorrectCount[numberVocabReviewNow] = (incorrectCount[numberVocabReviewNow] || 0) + 1;

                // Nếu số lần sai là 2, đặt numberHowtolearnNow = 0 và giữ nguyên từ hiện tại
                if (incorrectCount[numberVocabReviewNow] === 2) {
                    const updatedIncorrectThree = [...incorrectThree, numberVocabReviewNow];
                    return {
                        numberHowtolearnNow: 0,
                        numberVocabReviewNow,
                        score: prevState.score,
                        incorrectVocabReview,
                        incorrectCount,
                        incorrectThree: updatedIncorrectThree,
                    };
                } else if (incorrectCount[numberVocabReviewNow] === 3) {

                    const updatedCompletedVocabReview = [...completedVocabReview, numberVocabReviewNow];
                    
                    // Xóa từ này khỏi incorrectVocabReview để tránh học lại
                    const updatedIncorrectVocabReview = incorrectVocabReview.filter(item => item !== numberVocabReviewNow);

                    // Chuyển sang từ sai tiếp theo chưa hoàn thành trong `incorrectVocabReview`
                    const nextIncorrectVocabReview = updatedIncorrectVocabReview.find(
                        item => !updatedCompletedVocabReview.includes(item)
                    );

                    return {
                        numberHowtolearnNow: 0,
                        numberVocabReviewNow: nextIncorrectVocabReview,
                        score: prevState.score + ((100 / (vocabReview * 2) * 2)),
                        incorrectVocabReview: updatedIncorrectVocabReview,
                        completedVocabReview: updatedCompletedVocabReview,
                        incorrectCount,
                    };
                }

                // Nếu chỉ sai lần đầu, thêm vào `incorrectVocabReview` nếu chưa có trong danh sách
                if (!incorrectVocabReview.includes(numberVocabReviewNow) && !completedVocabReview.includes(numberVocabReviewNow)) {
                    incorrectVocabReview.push(numberVocabReviewNow);
                }

                // Chọn từ tiếp theo để ôn lại, bỏ qua các từ đã hoàn thành
                let nextVocabReviewToReview = (numberVocabReviewNow + 1) % vocabReviews.length;
                while (completedVocabReview.includes(nextVocabReviewToReview)) {
                    nextVocabReviewToReview = (nextVocabReviewToReview + 1) % vocabReviews.length;
                }

                return {
                    numberHowtolearnNow: 0,
                    numberVocabReviewNow: nextVocabReviewToReview,
                    score: prevState.score,
                    incorrectVocabReview,
                    incorrectCount,
                };
            });
        }
    };


    howToLearnBackListen = (vocabReview) => {
        this.setState((prevState) => ({
            numberHowtolearnNow: 0,
            score: prevState.score - (100 / (vocabReview * 2))
        }));
    }
    howToLearn = (user_id) => {
        if (this.props.review.reviewVocabData !== null) {
            const vocabReviewId = this.props.review.reviewVocabData.filter((value) => value.user_id === user_id);
            const vocabReview = vocabReviewId.slice(0, 12);
            // console.log(vocabReview);
            const { numberHowtolearnNow, numberVocabReviewNow, completedVocabReview, incorrectThree } = this.state;
            if (completedVocabReview.length >= vocabReview.length) {
                const completedVocabReviewfind = vocabReview.filter((vocab, index) => !incorrectThree.includes(index));
                const incorrectThreeFind = vocabReview.filter((vocab, index) => incorrectThree.includes(index));

                return (<ReviewSuccess vocabReview = {vocabReview} completedReview={completedVocabReviewfind} incorrectThree={incorrectThreeFind} user_id={user_id} linkto='/homeMember' />)
            } else {
                const vocabReviewNow = vocabReview[numberVocabReviewNow];
                switch (numberHowtolearnNow) {
                    case 0:
                        return <ViewAndWrite key={`${numberVocabReviewNow}-${numberHowtolearnNow}`} name={vocabReviewNow.name} mean={vocabReviewNow.mean} sound={vocabReviewNow.sound} pronunciation={vocabReviewNow.pronunciation} example={vocabReviewNow.example} example_mean={vocabReviewNow.example_mean} sino_vietnamese_sound={vocabReviewNow.sino_vietnamese_sound} howToLearnNext={() => this.howToLearnNext(vocabReview.length)} howToLearnBackWrite={() => this.howToLearnBackWrite(vocabReview.length)} />
                    case 1:
                        return <ViewAndChooseMean key={`${numberVocabReviewNow}-${numberHowtolearnNow}`} vocab_id={vocabReviewNow.vocab_id} sound={vocabReviewNow.sound} name={vocabReviewNow.name} example={vocabReviewNow.example} example_mean={vocabReviewNow.example_mean} mean={vocabReviewNow.mean} pronunciation={vocabReviewNow.pronunciation} sino_vietnamese_sound={vocabReviewNow.sino_vietnamese_sound} howToLearnNext={() => this.howToLearnNext(vocabReview.length)} howToLearnBackWrite={() => this.howToLearnBackListen(vocabReview.length)} />
                    default:
                        break;
                }
            }
        }
    }
    render() {
        // console.log('incorrectVocabReview :' + this.state.incorrectVocabReview);
        // console.log('complete :' + this.state.completedVocabReview);
        // console.log('incorrectThree :' + this.state.incorrectThree);
        const { user } = this.props.auth;
        const { isNavigateLogOut } = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        return (
            <main>
                <div className="content">
                    <div className="container">
                        <div className="studyVocab">
                            <ProgressVocab linkto={'/homeMember'} score={this.state.score} />
                            {/* end progress_vocab */}
                            <div className="studyVocabLesson">
                                <div className="howToLearn">
                                    {this.howToLearn(user.user_id)}
                                </div>
                                {/*end howToLearn */}
                            </div>
                            {/* end studyVocabLesson */}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        review: state.review
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    reviewVocab
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewVocab)